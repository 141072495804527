<template>
  <div class="app-container">
   <!-- todo -->
    <div class="top_btns">
      <div class="left_box">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" class="filter-item" size="small" @keyup.enter="getList" clearable/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width:580px" label-width="100px" :inline="true">
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px;margin: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:140px;margin: 0;"></selecttaxtype>
            </el-form-item> 
            <el-form-item label="公司类型："> 
            <el-select v-model="listQuery.companyType" placeholder="请选择公司类型" size="small" filterable clearable style="width:140px;margin: 0;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="税局："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="报税手机号：">          
              <el-input v-model.trim="listQuery.tel" size="small" style="width:140px;" placeholder="请输入报税手机号"></el-input>
            </el-form-item>
            <el-form-item label="授权状态：">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px;margin: 0;"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="是否零申报：">          
              <el-radio-group v-model="listQuery.accountingType">
              <el-radio :label="3">全部</el-radio>
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group> 
            </el-form-item>
            <el-form-item label="税款确认状态：" v-if="orgId == 3576" label-width="105px">
              <select-common
                placeholder="请选择确认状态"
                v-model:modelValue="listQuery.ackStatus"
                :options="confirmOption"
                class="w-140 ml-mr-0"
              />
            </el-form-item>
            <el-form-item label="任务状态：">
              <selectTaskStatus v-model:taskStatus="listQuery.taskStatus"></selectTaskStatus>
            </el-form-item>
            <el-form-item label="检查状态：">
              <selectJcBussinessStatus v-model:allStatus="listQuery.businessStatus"></selectJcBussinessStatus>
            </el-form-item>
          </el-form>
        </search>
        <span
        style="
          font-size: 13px;
          color: var(--themeColor, #17a2b8);
          margin-left: 10px;
        "
        ><i class="iconfont icon-gantanhao1" style="font-size: 13px"></i
        >温馨提示：本栏目仅检查电子税局税种，不包含个税检查！</span
      >
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()" :disabled="!$buttonStatus('pljc_sjjc_jc')" :loading="jcLoading">
          <el-icon><Checked /></el-icon><span  > 批量检查</span>
        </el-button>
        <el-button size="small" type="success" @click="daochu">
          <el-icon><Folder /></el-icon><span  > 导出</span>
        </el-button>
      </div>
    </div>
    <div class="">
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" v-loading="listLoading" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="240">
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId">
            </TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column prop="accountingType" label="是否零申报" width="90">
          <template #default="scope">
            <span v-if="scope.row.accountingType == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column> 
        <el-table-column  prop="district" label="税局" width="65">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district)  }}</span>  
          </template>
        </el-table-column>
        <el-table-column label="税款确认状态" width="100" v-if="orgId == 3576">
          <template #default="scope">
            <div class="item_icon">
              <i :class="$homeConfirmIcon(scope.row.djqrTaxStatus)"></i>
              <span>{{ $homeConfirmStatus(scope.row.djqrTaxStatus) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="districtName" label="任务状态" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p><i :class="$setUpStatusIconJc(scope.row.taskStatus, 'task')"></i>
                  <el-tooltip :disabled="scope.row.errLog == ''" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.errLog?scope.row.errLog:''"></div>
                    </template>
                    <p>{{$setUpStatusJc(scope.row.taskStatus, "task")}}<i class="iconfont icon-wenhao" v-if="scope.row.taskStatus == 4 && scope.row.errLog" style="color:#f56c6c;margin:0 0 0 4px;"></i></p>
                  </el-tooltip>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="districtName" label="检查状态" min-width="180">
          <template #default="scope">
            <div class="div_p" v-if="scope.row.taskStatus != '0' && scope.row.taskStatus != '' && scope.row.taskStatus != '1' && scope.row.taskStatus != '2'">
              <div class="item_icon">
                <p>
                  <span v-if="scope.row.image" class="demo-image__preview" >
                    <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="(scope.row.bussinessStatus == 3) || (scope.row.bussinessStatus == 30)" @click="open(scope.row, scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="(scope.row.bussinessStatus == 2)" @click="open(scope.row, scope.$index)"></i>
                    <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if=" (scope.row.bussinessStatus == 50)" @click="open(scope.row, scope.$index)"></i>
                  </span>
                  <i v-if="!scope.row.image" :class="$setUpStatusIconJc(scope.row.bussinessStatus, 'business')"></i>
                  <el-tooltip :disabled="scope.row.bussinessLog == ''" class="item" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:''"></div>
                    </template>
                    <span size="small" style="border: 0;" :class="$setUpStatusGsWordZf('5',scope.row.bussinessStatus)">{{$setUpStatusJc(scope.row.bussinessStatus, 'business')}}
                      <i class="iconfont icon-wenhao" v-if="scope.row.bussinessStatus != 2 && scope.row.bussinessLog" style="color:#f56c6c;"></i>
                    </span>
                  </el-tooltip>
                </p>
                
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button @click="handleUpdate(scope.row)" size="small" type="text" :disabled="!$buttonStatus('pljc_sjjc_jc')">检查</el-button>
              <el-button :disabled="scope.row.taskStatus == 0" @click="declareDetail(scope.row)" size="small" type="text">检查记录</el-button>
            </div>
          </template>
        </el-table-column>
        
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="taxCheckSj"/>
    </div>
  </div>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <declareDetails ref="declareDetails"></declareDetails>
  <settleHint />
  <sbImage ref="sbImage"/>
</template>

<script>
import { companyInitTaxList } from "@/api/company"
import {wbVoucherFxOther} from '@/api/export'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import sbImgUrl from "./sbImgUrl"
import selectTaskStatus from "./select/selectTaskSatatus.vue"
import selectJcBussinessStatus from "./select/selectJcBussinessStatus.vue"
import settleHint from "./settleHint.vue";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import sbImage from '@/components/sbImage'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION,HOME_CONFIRM_STATUS } from '@/utils/commonData'
export default {
  name: "taxCheck",
  components:{
    selecttaxtype,
    selectTaskStatus,
    selectJcBussinessStatus,
    declareDetails,
    sbImgUrl,
    settleHint,
    selectcity,
    selectAccreditStatus,
    sbImage,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      orgId: this.$store.getters["user/user"].orgId,
      activeName:'税款申报',
      listQuery: {
        page: 1,
        limit: 20,
        taskName:"tax-jc-shenbao",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 3,
        taxNames:[],
        status: 2,
        tel:""
      },
      total:0,
      list:[],
      gsMainIds: [],
      pageStatus: true,
      listLoading:false,
      jcLoading:false,
      selects:[],
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false,
      options:COMPANY_TYPE_OPTION,
      confirmOption:HOME_CONFIRM_STATUS,
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.listQuery.limit = localStorage.getItem('taxCheckSj') ? localStorage.getItem('taxCheckSj')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(190)
    this.initBus()
  },
  mounted(){
  },
  methods:{
    initBus(){
      if(!this.pageStatus){
        return
      }
      if(this.gsMainIds.length != 0){
        return
      }
      if(this.list.length == 0){
        return
      }
      this.$bus.on("refreshTask", () => {
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if ([1,2,99].includes(res.taskStatus)) {
            status = true;
          }
        })
        if(status == true) {
          companyInitTaxList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page: 1,
        limit: originLimit,
        taskName:"tax-jc-shenbao",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        businessStatus: [],
        allStatus: [],
        accountingType: 3,
        taxNames:[],
        status: 2,
        tel:""
      }
      this.getList()
    },
    getList(){
      this.listLoading = true
      companyInitTaxList(this.listQuery).then(res=>{
        this.listLoading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    open(row, index) {
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.image.match(',')){
          let imgUrls = res.image.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:""
              })
            }
          })
        }else{
          if(res.image){
            arr.push({
              img:'https://file.listensoft.net' + res.image + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    handleClick() {
      if(this.activeName == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(this.activeName == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    getIds(){
      this.gsMainIds = []
      this.selects.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.allChecked = false
      }
    },
    // 申报详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,e.taskName)
    },
    //批量申报
    declaration() {
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      //过滤授权状态
      let str = ''
      this.selects.map(v=>{
        if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
          str = v.name + '未授权登录，请先授权再发起任务！'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      this.$confirm('确定要批量检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jcLoading = true
        let param = {
          taskName: 'tax-jc-shenbao',
          comIds: this.gsMainIds,
          period: this.listQuery.period
        }
        sendTask(param).then(res => {
          this.jcLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    //申报
    handleUpdate(e) {
      if(e.district != 'zhejiang' && e.district != 'guangdong' && e.district != 'tianjin' && e.district != 'hubei' && e.loginMethod == '新版登录' && e.yzmType == 1 && e.keepStatus != 1){
        this.$qzfMessage('未授权登录，请先授权再发起任务！',1)
        return
      }
      this.$confirm('确定要检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-jc-shenbao',
          comIds: [e.comId],
          period: this.listQuery.period
        }
        this.listLoading = true
        sendTask(param).then(res => {
          this.listLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
     // 表格排序
     sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList()
    },
    //批量导出
    daochu(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.comId)
      })
      let param = {
        outType:"excel",
        exportType:"tax_jc_shenbao",
        query:this.listQuery
      }
      param.query.comIds = ids
      wbVoucherFxOther(param).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
// .xgmStyle,.ybStyle {
//   margin-left: 10px;
// }
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
</style>