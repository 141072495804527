<template>
  <div class="app-container">
    <el-card class="box_card">
      <div class="main">
        <div class="pic">
          <div class="order_amount">
            <div class="order_amount_icon">
              <i class="iconfont icon-jiezhang"></i>
            </div>
            <div>我的余额：&nbsp;</div>
            <span>{{ info.coins + info.coinsGive }}</span
            ><span style="margin-left: 5px">元</span>
          </div>
          <span
            v-if="info.coinsGive"
            style="font-size: 12px; margin-left: 28px; color: #fff"
            >（含赠送余额{{ info.coinsGive }}元）</span
          >
          <div class="order_btn">
            <el-button size="small" style="color: #17a2b8" @click="hint1()"
              >账户充值</el-button
            >
          </div>
        </div>
        <div class="main_right">
          <table class="content" cellspacing="0" width="100%">
            <tr class="weight">
              <td style="width: 25%">基础套餐</td>
              <td class="center" style="width: 25%">账套数</td>
              <td class="center" style="width: 25%">服务周期</td>
              <td class="center" style="width: 25%">操作</td>
            </tr>
            <tr>
              <td>智能财税SAAS系统</td>
              <td class="center fcolor">{{ info.count }}</td>
              <td class="center fcolor">
                {{ $parseTime(info.countTime, "{y}-{m}-{d}") }} -
                {{ info.countUseTime }}
              </td>
              <td class="center">
                <el-button size="small" type="primary" @click="renew"
                  >续费</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="checkRecord"
                  plain
                  >续费记录</el-button
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </el-card>
    <div class="list">
      <div class="list_top">
        <div class="list_icon">
          <i
            class="iconfont icon-beizhu"
            style="color: #fff; font-size: 16px"
          ></i>
        </div>
        <span style="font-weight: 600; font-size: 14px"
          >Ai财务工厂订单记录</span
        >
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        :height="contentStyleObj"
        v-loading="loading"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="index" label="序号" width="55" align="center" />
        <el-table-column
          prop="period"
          label="账期"
          min-width="150"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="content"
          label="扣费项目"
          min-width="280"
          align="center"
        >
          <template #default="scope"> Ai财务工厂 </template>
        </el-table-column>
        <el-table-column
          prop="amountTotal"
          label="金额"
          min-width="280"
          align="center"
        >
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              plain
              @click="checkDetail(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <el-drawer
    title="详情"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="70%"
  >
    <el-table
      :data="detailList"
      style="width: 100%"
      border
      :height="contentStyleObj1"
      v-loading="detailLoading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="name" label="公司名称" min-width="240">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <div class="text_left">
              <span v-if="scope.row.eaType == '1'" class="xgmStyle">小</span>
              <span v-if="scope.row.eaType == '2'" class="ybStyle">般</span>
              <span>{{ scope.row.eaName }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color: var(--themeColor, #17a2b8); cursor: pointer"
                @click="$copyComName(scope.row.eaName)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="period" label="账期" width="65" align="center" />
      <el-table-column
        prop="amount"
        label="基础费用"
        width="70"
        align="center"
      />
      <el-table-column prop="time1" label="发生时间" width="150" align="center">
      </el-table-column>
      <el-table-column
        prop="amountZj"
        label="追加费用"
        width="70"
        align="center"
      >
      </el-table-column>
      <el-table-column label="合计金额" width="70" align="center">
        <template #default="scope">
          {{ scope.row.amount + scope.row.amountZj }}
        </template>
      </el-table-column>
      <el-table-column prop="time2" label="发生时间" width="150" align="center">
      </el-table-column>
      <el-table-column
        prop="amountZs"
        label="赠送费用"
        width="70"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="count" label="单据数量" width="70" align="center">
      </el-table-column>
    </el-table>
  </el-drawer>
  <orgRenew ref="orgRenewRef" @success="getInfo" />
  <renewRecord ref="renewRecordRef" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import {
  orderOrgInfo,
  autoExpenseList,
  autoExpenseDetailList,
} from "@/api/org";
import orgRenew from "./components/orgRenew.vue";
import renewRecord from "./components/renewRecord.vue";
const { proxy } = getCurrentInstance();
const contentStyleObj = ref({});
const info = ref({
  coins: 0,
  coinsGive: 0,
});
const list = ref([]);
const loading = ref(false);
const drawer = ref(false);
const contentStyleObj1 = ref({});
const detailList = ref([]);
const detailLoading = ref(false);
const orgRenewRef = ref();
const renewRecordRef = ref();
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(365);
  contentStyleObj1.value = proxy.$getHeight(240);
  getList();
  getInfo();
});
const getInfo = () => {
  orderOrgInfo({}).then((res) => {
    if (res.data.msg == "success") {
      info.value = res.data.data;
    }
  });
};
const getList = () => {
  loading.value = true;
  autoExpenseList({}).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data ? res.data.data : [];
    }
  });
};
//查看详情
const checkDetail = (row) => {
  drawer.value = true;
  detailLoading.value = true;
  autoExpenseDetailList({ period: row.period }).then((res) => {
    detailLoading.value = false;
    if (res.data.msg == "success") {
      detailList.value = res.data.data ? res.data.data : [];
    }
  });
};
const hint1 = () => {
  ElMessage.warning("暂未开放");
};
const renew = () => {
  orgRenewRef.value.init(info.value);
};
const checkRecord = () => {
  renewRecordRef.value.init();
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
}
.pic {
  width: 290px;
  height: 140px;
  background-image: url("../../assets/order.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.order_amount {
  padding: 35px 0 0 10px;
  color: #fff;
  display: flex;
  align-items: center;
  .order_amount_icon {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 3px;
    margin-right: 5px;
    text-align: center;
    line-height: 20px;
    .iconfont {
      color: #17a2b8;
      font-size: 18px;
    }
  }
}
.order_btn {
  position: absolute;
  bottom: 10px;
  right: 30px;
}

.main_right {
  width: calc(100% - 300px);
  margin-left: 20px;
}
.content {
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-bottom: 1px solid #efefef;
    font-size: 13px;
    line-height: 35px;
  }
}
.center {
  text-align: center;
}
.fcolor {
  color: #17a2b8;
}
.weight {
  font-weight: 600;
}
.list {
  padding: 20px;
  background-color: #f8f8f8;
}
.list_top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .list_icon {
    width: 20px;
    height: 20px;
    background-color: #17a2b8;
    border-radius: 3px;
    margin-right: 5px;
    text-align: center;
  }
}
.top_color {
  background-color: #ecf7f9;
}
.content_list {
  width: 100%;
  border: 1px solid #e5ecf0;
  border-bottom: none;
  border-right: none;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #e5ecf0;
    border-bottom: 1px solid #e5ecf0;
    line-height: 35px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}

.text_left {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
