import request from '@/utils/request'


// 续费
export function ReNewGetUrl(data) {
  return request({
    url: 'api/v2/pay/ReNewGetUrl',
    method: 'post',
    data
  })
}

//续费记录
export function payList(data) {
  return request({
    url: 'api/v2/pay/payList',
    method: 'post',
    data
  })
}

//金额计算
export function getAmount(data) {
  return request({
    url: 'api/v2/pay/getAmount',
    method: 'post',
    data
  })
}