<template>
  <el-drawer
    title="续费记录"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="900px"
  >
    <el-radio-group
      v-model="listQuery.tradeState"
      class="mb-10"
      @change="getList"
      size="small"
    >
      <el-radio-button label="">全部</el-radio-button>
      <el-radio-button label="1">成功</el-radio-button>
      <el-radio-button label="0">未支付</el-radio-button>
    </el-radio-group>
    <el-table
      :data="list"
      style="width: 100%"
      border
      v-loading="loading"
      :height="contentStyleObj"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="out_trade_no" label="订单号" min-width="240">
      </el-table-column>
      <el-table-column prop="amount" label="金额">
        <template #default="scope">
          {{ scope.row?.amount / 100 }}
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template #default="scope">
          {{ scope.row?.remark?.type }}
        </template>
      </el-table-column>

      <el-table-column label="增购户数">
        <template #default="scope">
          <span v-if="scope.row?.remark?.type == '增户'">
            {{ scope.row?.remark?.count }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="续费月数">
        <template #default="scope">
          <span v-if="scope.row?.remark?.type == '续费'">
            {{ scope.row?.remark?.count }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="支付结果" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row?.trade_state == 'SUCCESS'" type="success"
            >成功</el-tag
          >
          <el-tag v-else type="primary">未支付</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </el-drawer>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { payList } from "@/api/pay";
const { proxy } = getCurrentInstance();
const list = ref([]);
const loading = ref(false);
const drawer = ref(false);
const contentStyleObj = ref({});
const total = ref(0);
const listQuery = ref({
  page: 1,
  limit: 20,
  tradeState: "1",
});
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
});
const init = () => {
  drawer.value = true;
  getList();
};
const getList = () => {
  loading.value = true;
  payList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
      list.value.map((v) => {
        v.remark = JSON.parse(v.remark);
      });
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss"></style>
