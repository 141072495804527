<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="续费"
    width="580px"
    destroy-on-close
    append-to-body
  >
    <div class="renew_top">
      <el-radio-group v-model="form.type" @change="changeType">
        <el-radio label="增户" border>增户</el-radio>
        <el-radio label="续费" border>续费</el-radio>
      </el-radio-group>
      <div>
        <i class="iconfont icon-gantanhao1"></i>实扣金额：
        <span class="renew_amount">{{ acc }}</span>
      </div>
    </div>
    <div class="slider-demo-block">
      {{ title }}：
      <el-slider
        v-model="form.count"
        show-input
        :min="countMin"
        :max="countMax"
        @change="changeCount"
        @input="changeCount"
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="save" :loading="loading"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
  <div v-if="showStatus" style="z-index: 99999" class="pay">
    <div class="pay_main">
      <div class="pay_title">
        扫码支付 <i class="iconfont icon-weixinzhifu"></i>
      </div>
      <div style="color: #636161">请使用微信扫码支付</div>
      <div class="pay_img">
        <img :src="url" alt="" />
      </div>
      <div class="ai_close">
        <el-icon @click="closePay" size="20"> <Close /></el-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { ElMessage } from "element-plus";
import { ReNewGetUrl, getAmount, payList } from "@/api/pay";
const emit = defineEmits();
const dialogFormVisible = ref(false);
const form = ref({
  type: "",
  count: 1,
});
const countMin = ref(10);
const countMax = ref(1000);
const title = ref("增购户数");
const url = ref("");
const showStatus = ref(false);
const loading = ref(false);
const info = ref({});
const acc = ref(0);
const timer = ref(null);
const amount = computed(() => {
  let num = 0;
  if (form.value.type == "增户") {
    let diffMonth = calculateMonthsUpward(info.value.countUseTime);
    num = diffMonth * 3 * form.value.count * 1;
  } else {
    num = form.value.count * 1 * 3 * info.value.count * 1;
  }
  return num;
});
const calculateMonthsUpward = (dateString) => {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  // 计算年份差和月份差
  let yearDiff = targetDate.getFullYear() - currentDate.getFullYear();
  let monthDiff = targetDate.getMonth() - currentDate.getMonth();
  // 总月数差
  let totalMonthDiff = yearDiff * 12 + monthDiff;
  // 如果当前日期的天数小于目标日期的天数，则减去一个月
  if (currentDate.getDate() < targetDate.getDate()) {
    totalMonthDiff += 1;
  }
  // 向上取整
  return Math.ceil(totalMonthDiff);
};
const init = (item) => {
  info.value = item;
  reset();
  dialogFormVisible.value = true;
};
const changeType = () => {
  title.value = form.value.type == "增户" ? "增购户数" : "续费月数";
  form.value.count = form.value.type == "增户" ? 10 : 1;
  if (form.value.type == "增户") {
    countMin.value = 10;
    countMax.value = 1000;
  } else {
    countMin.value = 1;
    countMax.value = 36;
  }
  changeCount();
};
const save = () => {
  if (!form.value.type) {
    ElMessage.warning("请选择续费类型");
    return;
  }
  loading.value = true;
  let param = {
    type: form.value.type,
    count: form.value.count * 1,
  };
  ReNewGetUrl(param).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      url.value = "https://file.listensoft.net" + res.data.data.url;
      showStatus.value = true;
      timer.value && clearInterval(timer.value);
      timer.value = setInterval(() => {
        getPayStatus(res.data.data.outTradeNo);
      }, 5 * 1000);
    }
  });
};
const getPayStatus = (param) => {
  payList({ outTradeNo: param }).then((res) => {
    if (res.data.msg == "success") {
      if (res.data.data.list[0].trade_state == "SUCCESS") {
        ElMessage.success("支付成功");
        showStatus.value = false;
        timer.value && clearInterval(timer.value);
        dialogFormVisible.value = false;
        emit("success")
      }
    }
  });
};
const closePay = () => {
  clearInterval(timer.value);
  showStatus.value = false;
};
const changeCount = () => {
  let param = {
    type: form.value.type,
    count: form.value.count * 1,
  };
  getAmount(param).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      acc.value = res.data.data / 100;
    }
  });
};
const reset = () => {
  form.value = {
    type: "增户",
    count: 10,
  };
  changeType();
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.slider-demo-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.slider-demo-block .el-slider {
  width: 82%;
  margin-top: 0;
  margin-left: 12px;
}
.pay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  .pay_main {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -12%);
    top: 25%;
    z-index: 999;
    width: 315px;
    background-color: #fff;
    border-radius: 16px;
    text-align: center;
    padding: 30px 0 20px;
  }
  .pay_title {
    font-size: 22px;
    margin-bottom: 15px;
    .iconfont {
      color: #15ba11;
      font-size: 14px;
    }
  }
  .pay_img {
    margin-top: 30px;
    img {
      width: 230px;
      height: 230px;
    }
  }
  .ai_close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}
.renew_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .iconfont {
    color: var(--themeColor, #17a2b8);
    margin-right: 4px;
    font-size: 13px;
  }
  .renew_amount {
    color: var(--themeColor, #17a2b8);
  }
}
</style>
